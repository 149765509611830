(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/coupon.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/coupon.js');
"use strict";


const {
  CouponContextProvider
} = svs.components.tipsen.couponComponents;
const Coupon = _ref => {
  let {
    children
  } = _ref;
  return React.createElement(CouponContextProvider, null, children);
};
setGlobal('svs.components.tipsen.couponComponents.Coupon', Coupon);

 })(window);