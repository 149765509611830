(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/coupon-context-provider.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/coupon-context-provider.js');
"use strict";


const {
  useMemo
} = React;
const {
  useCouponId,
  useCurrentCouponRows
} = svs.components.tipsen.couponId;
const CouponContext = React.createContext();
const CouponContextProvider = _ref => {
  let {
    children
  } = _ref;
  const couponRows = useCurrentCouponRows();
  const couponId = useCouponId();
  const context = useMemo(() => ({
    couponRows,
    couponId
  }), [couponRows, couponId]);
  return React.createElement(CouponContext.Provider, {
    value: context
  }, children);
};
setGlobal('svs.components.tipsen.couponComponents', {
  CouponContext,
  CouponContextProvider
});

 })(window);