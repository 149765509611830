(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/web-text.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/web-text.js');
"use strict";

const {
  Coupon
} = svs.components.tipsen.couponComponents;
const WebText = _ref => {
  let {
    eventComment = ''
  } = _ref;
  return React.createElement("div", {
    className: "pg_web_text"
  }, eventComment);
};
Coupon.WebText = WebText;

 })(window);