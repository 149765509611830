(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/participant.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/participant.js');
"use strict";

const {
  useMemo
} = React;
const {
  Coupon
} = svs.components.tipsen.couponComponents;
const {
  SmartAbbr,
  constants: {
    getTeamName,
    DescriptionSize
  }
} = svs.components.lbUi.participants;
const getTeamNameAndTitle = (participant, nameSize) => {
  const name = getTeamName(participant, nameSize);
  const title = (participant === null || participant === void 0 ? void 0 : participant.name) || name;
  return [name, title];
};
const Participant = _ref => {
  let {
    nameSize = DescriptionSize.Medium,
    participant
  } = _ref;
  const [teamName, teamTitle] = useMemo(() => getTeamNameAndTitle(participant, nameSize), [participant, nameSize]);
  return React.createElement(SmartAbbr, {
    title: teamTitle
  }, teamName);
};
Coupon.Participant = Participant;

 })(window);