(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/bet-prediction.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/bet-prediction.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  Coupon
} = svs.components.tipsen.couponComponents;
const {
  selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const {
  useAnalysisOutcomes
} = svs.components.sportinfo.gameAnalysesRedux.hooks;
const {
  selectMatchAnalysis,
  selectAuthorProfile
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const {
  OutcomePair
} = svs.components.lbUi;
const BetPredictionRow = _ref => {
  var _allHomeScoreValues$, _allAwayScoreValues$;
  let {
    analysisId
  } = _ref;
  const matchAnalysis = useSelector(state => selectMatchAnalysis(state, analysisId));
  const authorProfile = useSelector(state => selectAuthorProfile(state, matchAnalysis === null || matchAnalysis === void 0 ? void 0 : matchAnalysis.authorId));
  const outcomes = useAnalysisOutcomes(matchAnalysis);
  const authorShortName = authorProfile.authorShortName;
  const lastChar = authorShortName.slice(-1).toLowerCase();
  const endsWithSorX = lastChar === 's' || lastChar === 'x';
  const rowTitle = endsWithSorX ? "".concat(authorShortName, " speltips") : "".concat(authorShortName, "s speltips");
  const allHomeScoreValues = outcomes[0];
  const allAwayScoreValues = outcomes[1];
  const homeScore = (_allHomeScoreValues$ = allHomeScoreValues[0]) === null || _allHomeScoreValues$ === void 0 ? void 0 : _allHomeScoreValues$.description;
  const awayScore = (_allAwayScoreValues$ = allAwayScoreValues[0]) === null || _allAwayScoreValues$ === void 0 ? void 0 : _allAwayScoreValues$.description;
  return React.createElement("div", {
    className: "pg_tipsen__bet_prediction__row"
  }, React.createElement("span", {
    className: "pg_tipsen_bet_prediction__row__title"
  }, rowTitle), React.createElement("div", {
    className: "pg_tipsen_bet_prediction__row__outcome"
  }, React.createElement(OutcomePair, {
    outcomeAway: awayScore,
    outcomeHome: homeScore
  })));
};
const BetPrediction = _ref2 => {
  let {
    productId,
    drawNumber,
    matchId,
    eventNumber
  } = _ref2;
  const matchAnalysesIds = useSelector(state => selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber(state, productId, drawNumber, matchId, eventNumber));
  if (!matchAnalysesIds || matchAnalysesIds.length === 0) {
    return React.createElement(React.Fragment, null, " F\xF6r tillf\xE4llet har vi inga speltips ");
  }
  return React.createElement("div", {
    className: "pg_tipsen_bet_predictions"
  }, matchAnalysesIds.map(analysisId => React.createElement(BetPredictionRow, {
    analysisId: analysisId,
    key: analysisId
  })));
};
Coupon.BetPrediction = BetPrediction;

 })(window);