(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/outcome-row.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/outcome-row.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  Coupon,
  useCouponContext
} = svs.components.tipsen.couponComponents;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  selectSystemType
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  Outcome
} = svs.components.lbUi.outcome;
const percentage = signDistribution => {
  const signDistributionPercentage = [];
  const totalRowValue = signDistribution.reduce((a, b) => a + b, 0);
  signDistributionPercentage[0] = signDistribution[0] ? "".concat(Math.round(signDistribution[0] / totalRowValue * 100), "%") : "".concat(0, "%");
  signDistributionPercentage[1] = signDistribution[1] ? "".concat(Math.round(signDistribution[1] / totalRowValue * 100), "%") : "".concat(0, "%");
  signDistributionPercentage[2] = signDistribution[2] ? "".concat(Math.round(signDistribution[2] / totalRowValue * 100), "%") : "".concat(0, "%");
  return signDistributionPercentage;
};
const getBetOptionSign = (betOption, index, signDistribution, systemType) => {
  if (systemType === SYSTEM_REDUCERA_FRITT) {
    const percentages = percentage(signDistribution);
    return percentages[index];
  }
  return betOption.sign;
};
const OutcomeRow = _ref => {
  let {
    buttonClassName = '',
    eventNumber = 0,
    className = '',
    isClickable = false,
    outcomeGroupIndex = 0,
    outcomes = [],
    signDistribution = [],
    signLabel = '',
    boardIndex = 0
  } = _ref;
  const {
    couponId
  } = useCouponContext();
  const [productBranding] = useBranding();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const isReduceFree = systemType === SYSTEM_REDUCERA_FRITT;
  const classNames = ['pg_tipsen__outcome_row '];
  if (className) {
    classNames.push(className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, signLabel && React.createElement("div", {
    "aria-label": "matematisk gardering",
    className: "f-normal"
  }, signLabel), outcomes.map((betOption, index) => {
    const dataTestId = "outcome_".concat(boardIndex, "_").concat(eventNumber, "_").concat(outcomeGroupIndex, "_").concat(index);
    return React.createElement(Outcome, {
      branding: productBranding,
      className: buttonClassName,
      dataTestId: dataTestId,
      hasUSign: betOption.hasUSign,
      isClickable: isClickable,
      isReduceFree: isReduceFree,
      isSelected: betOption.isSelected,
      key: "outcome_".concat(index + 1, "_").concat(betOption.sign),
      sign: getBetOptionSign(betOption, index, signDistribution, systemType)
    });
  }));
};
Coupon.OutcomeRow = OutcomeRow;

 })(window);