(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/game-analysis.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/game-analysis.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  Coupon
} = svs.components.tipsen.couponComponents;
const {
  selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const {
  selectMatchAnalysis,
  selectAuthorProfile
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const GameAnalysisRow = _ref => {
  let {
    analysisId
  } = _ref;
  const matchAnalysis = useSelector(state => selectMatchAnalysis(state, analysisId));
  const authorProfile = useSelector(state => selectAuthorProfile(state, matchAnalysis === null || matchAnalysis === void 0 ? void 0 : matchAnalysis.authorId));
  return React.createElement("div", {
    className: "pg_tipsen_game_analysis__row"
  }, React.createElement("span", {
    className: "pg_tipsen_game_analysis__row__header f-medium"
  }, "Analys av ".concat(authorProfile.authorLongName)), React.createElement("div", {
    className: "pg_tipsen_game_analysis__row__body"
    ,
    dangerouslySetInnerHTML: {
      __html: matchAnalysis.body
    }
  }));
};
const GameAnalysis = _ref2 => {
  let {
    productId,
    drawNumber,
    matchId,
    eventNumber
  } = _ref2;
  const matchAnalysesIds = useSelector(state => selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber(state, productId, drawNumber, matchId, eventNumber));
  if (!matchAnalysesIds || matchAnalysesIds.length === 0) {
    return React.createElement(React.Fragment, null, "F\xF6r tillf\xE4llet har vi inga spelanalyser");
  }
  return React.createElement("div", {
    className: "pg_tipsen_game_analyzes"
  }, matchAnalysesIds.map(analysisId => React.createElement(GameAnalysisRow, {
    analysisId: analysisId,
    key: analysisId
  })));
};
Coupon.GameAnalysis = GameAnalysis;

 })(window);