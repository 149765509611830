(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/reductions.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/reductions.js');
"use strict";


const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  Coupon
} = svs.components.tipsen.couponComponents;
const {
  useCouponContext
} = svs.components.tipsen.couponComponents;
const {
  selectReduction
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectReductionSelectionsForEvent
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  toggleReduction
} = svs.components.tipsen.engine.actions;
const {
  Checkbox
} = svs.ui.reactForm;
const Reduction = _ref => {
  let {
    className,
    onClick,
    isSelected,
    value,
    id,
    disabled,
    labelMap
  } = _ref;
  const label = labelMap[value];
  const classNames = ['pg_reduction'];
  if (className) {
    classNames.push(className);
  }
  const handleOnChange = () => {
    onClick(value);
  };
  return React.createElement(Checkbox, {
    checked: isSelected,
    className: "".concat(classNames.join(' ')),
    disabled: disabled,
    id: id,
    name: id,
    onChange: handleOnChange,
    value: value
  }, label);
};
const Reductions = _ref2 => {
  let {
    className = '',
    eventNumber,
    labelMap
  } = _ref2;
  const {
    couponId
  } = useCouponContext();
  const dispatch = useDispatch();
  const reductionValues = useSelector(state => selectReduction(state));
  const selectedReductions = useSelector(state => selectReductionSelectionsForEvent(state, couponId, eventNumber));
  const unselectedReductions = reductionValues.filter(reduction => !selectedReductions.includes(reduction));
  const handleClick = reduction => {
    dispatch(toggleReduction({
      couponId,
      eventNumber,
      reduction
    }));
  };
  return reductionValues.map(reduction => React.createElement(Reduction, {
    className: className,
    disabled: unselectedReductions.length === 1 && unselectedReductions.includes(reduction),
    id: "reduction_".concat(eventNumber, "_").concat(reduction),
    isSelected: selectedReductions.includes(reduction),
    key: reduction,
    labelMap: labelMap,
    onClick: handleClick,
    value: reduction
  }));
};
Coupon.Reductions = Reductions;

 })(window);