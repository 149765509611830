(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/bet-buttons.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/bet-buttons.js');
"use strict";


const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  Coupon,
  useCouponContext
} = svs.components.tipsen.couponComponents;
const {
  TipsenBetButton
} = svs.components.lbUi.tipsenBetButton;
const {
  toggleOutcome
} = svs.components.tipsen.engine.actions;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  selectSystemType
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  TrendIndicator
} = svs.components.tipsen.trendIndicator;
const percentage = signDistribution => {
  const signDistributionPercentage = [];
  const totalRowValue = signDistribution.reduce((a, b) => a + b, 0);
  signDistributionPercentage[0] = signDistribution[0] ? "".concat(Math.round(signDistribution[0] / totalRowValue * 100), "%") : "".concat(0, "%");
  signDistributionPercentage[1] = signDistribution[1] ? "".concat(Math.round(signDistribution[1] / totalRowValue * 100), "%") : "".concat(0, "%");
  signDistributionPercentage[2] = signDistribution[2] ? "".concat(Math.round(signDistribution[2] / totalRowValue * 100), "%") : "".concat(0, "%");
  return signDistributionPercentage;
};
const getBetOptionSign = (betOption, index, signDistribution, systemType) => {
  if (systemType === SYSTEM_REDUCERA_FRITT) {
    const percentages = percentage(signDistribution);
    return percentages[index];
  }
  return betOption.sign;
};
const BetButtons = _ref => {
  let {
    boardIndex = 0,
    buttonClassName = '',
    distributions = [],
    eventNumber = 0,
    className = '',
    isClickable = false,
    outcomeGroupIndex = 0,
    outcomes = [],
    signDistribution = [],
    signLabel = ''
  } = _ref;
  const {
    couponId
  } = useCouponContext();
  const [productBranding] = useBranding();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const isReduceFree = systemType === SYSTEM_REDUCERA_FRITT;
  const dispatch = useDispatch();
  const classNames = ['pg_bet_buttons'];
  if (className) {
    classNames.push(className);
  }
  const handleClick = (index, e) => {
    e.preventDefault();
    const boardIndex = 0;
    dispatch(toggleOutcome({
      boardIndex,
      couponId,
      eventNumber,
      outcome: index,
      systemType,
      outcomeGroupIndex
    }));
  };
  const hasMatchingDistributions = outcomes.length > 0 && outcomes.length === distributions.length;
  return React.createElement("div", {
    className: classNames.join(' ')
  }, signLabel && React.createElement("div", {
    "aria-label": "matematisk gardering",
    className: "f-normal"
  }, signLabel), outcomes.map((betOption, index) => {
    const dataTestId = "bet_button_".concat(boardIndex, "_").concat(eventNumber, "_").concat(outcomeGroupIndex, "_").concat(index);
    const key = "bet_button_".concat(index + 1, "_").concat(betOption.sign);
    const trendIndicatorKey = "".concat(couponId, "_").concat(index);
    if (hasMatchingDistributions) {
      return React.createElement("div", {
        className: "pg_bet_buttons__button_wrapper",
        key: key
      }, React.createElement(TipsenBetButton, {
        branding: productBranding,
        className: buttonClassName,
        dataTestId: dataTestId,
        handleClick: e => handleClick(index, e),
        hasUSign: betOption.hasUSign,
        isClickable: isClickable,
        isReduceFree: isReduceFree,
        isSelected: betOption.isSelected,
        sign: getBetOptionSign(betOption, index, signDistribution, systemType)
      }), (distributions === null || distributions === void 0 ? void 0 : distributions.length) === outcomes.length && React.createElement("div", {
        className: "pg_bet_buttons__distribution"
      }, React.createElement(TrendIndicator, {
        id: trendIndicatorKey,
        key: trendIndicatorKey,
        suffix: "%",
        value: (distributions === null || distributions === void 0 ? void 0 : distributions[index]) || 0
      })));
    }
    return React.createElement(TipsenBetButton, {
      branding: productBranding,
      className: buttonClassName,
      dataTestId: dataTestId,
      handleClick: e => handleClick(index, e),
      hasUSign: betOption.hasUSign,
      isClickable: isClickable,
      isReduceFree: isReduceFree,
      isSelected: betOption.isSelected,
      key: key,
      sign: getBetOptionSign(betOption, index, signDistribution, systemType)
    });
  }));
};
Coupon.BetButtons = BetButtons;

 })(window);