(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-components/assets/javascripts/use-coupon-context.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-components/assets/javascripts/use-coupon-context.js');
"use strict";

const {
  useContext
} = React;
const {
  CouponContext
} = svs.components.tipsen.couponComponents;
const useCouponContext = () => {
  const context = useContext(CouponContext);
  if (!context) {
    throw new Error('Coupon components must be mounted inside coupon context');
  }
  return context;
};
setGlobal('svs.components.tipsen.couponComponents.useCouponContext', useCouponContext);

 })(window);